import axios from 'axios';

const client = axios.create({
	baseURL: '/api'
});

export function checkLogin(ott) {
	const headers = {};
	if(ott) {
		headers['PTMD-OTT'] = ott;
	}
	return client.get('/me', { headers }).then(r => r.data);
}

export function login(redirect, email, password, token) {
	return client.post('/login', {
		redirect, email, password, token
	});
}

export function resetPassword(email) {
	return client.post('/reset', { email });
}

export function logout() {
	return client.post('/logout');
}

export function emailLogin(email, redirect) {
	return client.post('/emaillogin', {
		redirect, email
	});
}
