import { useEffect } from 'react';

export default function TransparentBody({ children = null }) {
	useEffect(() => {
		document.body.style.background = 'transparent';
		document.body.style.padding = '0';
		return () => {
			document.body.style.background = '';
			document.body.style.padding = '';
		};
	}, []);
	return children;
}
