import React from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default function ErrorAlert({ error }) {
	const { t } = useTranslation();
	const message = error.response?.data?.message || error.message;
	return <Alert color="danger">
		<h4 className="alert-heading">{t(error.heading || 'Error.Unknown')}</h4>
		<p className="mb-0">{message}</p>
	</Alert>;
}
