import React, { useState } from 'react';
import { Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ErrorAlert from './ErrorAlert';
import { logout } from '../api';

export default function Logout({ noHeader, logout: onLogout }) {
	const { t } = useTranslation();
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(null);
	return <Form onSubmit={async e => {
		e.preventDefault();
		setSubmitting(true);
		try {
			await logout();
			onLogout();
		} catch(err) {
			setError(err);
			setSubmitting(false);
		}
	}}>
		{noHeader ? null : <h2 className="mb-3">{t('Logout.Logout')}</h2>}
		{error && <ErrorAlert error={error} />}
		<input type="submit" className="btn-ptmd" value={t('Logout.Logout')} disabled={submitting} />
	</Form>;
}
