import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './i18n/en';
import nl from './i18n/nl';

i18n.use(initReactI18next).use(LngDetector).init({
	resources: {
		en: { translation: en },
		nl: { translation: nl }
	},
	fallbackLng: 'en',
	keySeparator: false,
	interpolation: {
		escapeValue: false
	},
	react: {
		wait: true
	},
	detection: {
		order: ['querystring', 'navigator'],
		lookupQuerystring: 'lang'
	}
});
