import React, { useEffect, useRef, useState } from 'react';
import HttpStatus from 'http-status-codes';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import Login from './Login';
import Logout from './Logout';
import EmailLogin from './EmailLogin';
import ErrorAlert from './ErrorAlert';
import TransparentBody from './TransparentBody';
import { checkLogin } from '../api';

const DOMAINS = ['ptmd.nl', 'ptmd.co.uk', 'ptmd.de', 'ptmd.fr', 'ptmd.es', 'ptmd.it', 'ptmd.pl', 'daydreamhomedeco.nl', 'localhost'];

function checkRedirect(url) {
	if(url) {
		if('URL' in window) {
			const u = new URL(url);
			if(DOMAINS.some(d => u.hostname.endsWith(d))) {
				return url;
			}
		} else if(DOMAINS.some(d => url.includes(d))) {
			return url;
		}
	}
	return null;
}

function redirectTo(url, data) {
	const form = document.createElement('form');
	form.method = 'post';
	form.action = url;
	document.body.appendChild(form);
	if(data) {
		Object.keys(data).forEach(key => {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.value = data[key];
			input.name = key;
			form.appendChild(input);
		});
	}
	form.submit();
	document.body.removeChild(form);
}

export default function App() {
	const { t } = useTranslation();
	const [loggedIn, setLoggedIn] = useState(null);
	const [error, setError] = useState(null);
	const redirect = useRef({ onLogin: null, onLogout: null });
	const query = queryString.parse(window.location.search);
	const logout = () => {
		setLoggedIn(false);
		if(redirect.current.onLogout) {
			redirectTo(redirect.current.onLogout);
		}
	};
	const login = user => {
		setLoggedIn(true);
		if(redirect.current.onLogin) {
			redirectTo(redirect.current.onLogin, user);
		}
	};
	useEffect(() => {
		redirect.current = {
			onLogin: checkRedirect(query.login),
			onLogout: checkRedirect(query.logout)
		};
		checkLogin(query.ott).then(login).catch(err => {
			if(err.response?.status === HttpStatus.UNAUTHORIZED) {
				logout();
			} else {
				setError(err);
			}
		});
	}, []);
	if(query.type === 'logoutButton') {
		if(!loggedIn) {
			return <TransparentBody />;
		}
		return <TransparentBody>
			<Logout logout={logout} noHeader />
		</TransparentBody>;
	}
	return <>
		<header className="mb-4">
			<nav className="container">
				<a href="https://ptmd.nl/" className="d-inline-block">
					<img alt="PTMD" src="https://api.ptmd.nl/rest/images/v1/original/logo/logo" className="mw-100" />
				</a>
			</nav>
		</header>
		{loggedIn !== null && <div className="container">
			<div className="row bg-white">
				<div className="col-lg-6">
					<div className="p-5">
						{error && <ErrorAlert error={error} />}
						{loggedIn ? <Logout logout={logout} /> : <Login login={login} redirect={redirect.current.onLogin} />}
					</div>
				</div>
				{!loggedIn && <div className="col-lg-6 email-login-container">
					<div className="p-5">
						<EmailLogin redirect={redirect.current.onLogin} />
					</div>
				</div>}
			</div>
			{!loggedIn && <div className="bg-white row my-4">
				<div className="col-12">
					<div className="p-5">
						<h3>{t('Register.Header')}</h3>
						<p>{t('Register.Body')}</p>
						<a href="https://b2b.ptmd.nl/register" className="btn-register">{t('Register.Button')}</a>
					</div>
				</div>
			</div>}
		</div>}
		<footer className="mt-2">
			<div className="container">
				&copy; PTMD Collection
				| <a href="https://b2b.ptmd.nl/shop/privacy">{t('Footer.Privacy')}</a>{' '}
				| <a href="https://b2b.ptmd.nl/shop/disclaimer">{t('Footer.Disclaimer')}</a>{' '}
				| <a href="https://b2b.ptmd.nl/shop/contact">{t('Footer.Contact')}</a>
			</div>
		</footer>
	</>;
}
