import React, { useState, Fragment } from 'react';
import { Form, Input, FormGroup, Button } from 'reactstrap';
import HttpStatus from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import ErrorAlert from './ErrorAlert';
import { login, resetPassword } from '../api';

export default function Login({ login: onLogin, redirect: redirectUrl }) {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [reset, setReset] = useState(false);
	const [error, setError] = useState(null);
	const [token, setToken] = useState(null);
	if(reset) {
		return <div>
			<h1 className="text-center mb-3">{t('Login.Login')}</h1>
			<p>{t('Reset.Sent', { email })}</p>
		</div>;
	}
	return <Form onSubmit={async e => {
		e.preventDefault();
		try {
			setSubmitting(true);
			setError(null);
			let res;
			if(token === null) {
				res = await login(redirectUrl, email, password);
			} else {
				res = await login(redirectUrl, email, password, token);
			}
			if(res.status === HttpStatus.PARTIAL_CONTENT) {
				setToken('');
				setSubmitting(false);
			} else {
				onLogin(res.data);
			}
		} catch(err) {
			if(err.response) {
				if(err.response.status === HttpStatus.UNAUTHORIZED) {
					const unauthError = new Error(t(token === null ? 'Error.Wrong' : 'Error.Token'));
					unauthError.heading = 'Error.Auth';
					setError(unauthError);
					setSubmitting(false);
					return;
				} else if(err.response.status === HttpStatus.PRECONDITION_FAILED) {
					const { redirect } = err.response.data;
					if(redirect) {
						window.location = redirect;
						return;
					}
				}
			}
			setError(err);
			setSubmitting(false);
		}
	}}>
		<h2 className="mb-3">{t('Login.Login')}</h2>
		{error && <ErrorAlert error={error} />}
		{token === null ? <>
			<FormGroup key="login">
				<Input type="email" autoFocus placeholder={t('Login.Email')} value={email} onChange={e => setEmail(e.target.value.trim())} />
			</FormGroup>
			<FormGroup>
				<Input type="password" placeholder={t('Login.Password')} value={password} onChange={e => setPassword(e.target.value)} />
			</FormGroup>
			<div className="mt-1">
				<input type="submit" value={t('Login.Login')} className="btn-ptmd" disabled={submitting || !email || !password} />
				<Button color="link" onClick={async e => {
					e.preventDefault();
					setSubmitting(true);
					setError(null);
					try {
						await resetPassword(email);
						setReset(true);
						setPassword('');
					} catch(err) {
						if(err.response?.status === HttpStatus.NOT_FOUND) {
							const notFoundError = new Error(t('Error.Reset'));
							notFoundError.heading = 'Error.Auth';
							setError(notFoundError);
						} else {
							setError(err);
						}
						setSubmitting(false);
					}
				}} disabled={submitting || !email}>{t('Login.Reset')}</Button>
			</div>
		</> : <Fragment key="token">
			<FormGroup>
				<Input type="number" autoFocus placeholder={t('Login.Token')} value={token} onChange={e => setToken(e.target.value)} />
			</FormGroup>
			<div className="mt-1">
				<input type="submit" value={t('Login.Login')} className="btn-ptmd" disabled={submitting || !token} />
				<Button color="link" onClick={e => {
					e.preventDefault();
					setEmail('');
					setPassword('');
					setToken(null);
					setError('');
				}} disabled={submitting}>{t('Login.Cancel')}</Button>
			</div>
		</Fragment>}
	</Form>;
}
