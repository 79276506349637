import React, { useState } from 'react';
import { Form, Input, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ErrorAlert from './ErrorAlert';
import { emailLogin } from '../api';

export default function EmailLogin({ redirect }) {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [state, setState] = useState(false);
	const [error, setError] = useState(null);
	if(state === 'sent') {
		return <div className="text-center">
			<h1 className="mb-3">{t('Login.LoginEmail')}</h1>
			<p>{t('LoginEmail.Sent', { email })}</p>
		</div>;
	}
	return <Form onSubmit={async e => {
		e.preventDefault();
		try {
			setState(true);
			setError(null);
			await emailLogin(email, redirect);
			setState('sent');
		} catch(err) {
			setError(err);
			setState(false);
		}
	}}>
		<h2>{t('Login.LoginEmail')}</h2>
		{error && <ErrorAlert error={error} />}
		<p>{t('Login.LoginEmailDescription')}</p>
		<FormGroup key="login">
			<Input type="email" placeholder={t('Login.Email')} value={email} onChange={e => setEmail(e.target.value.trim())} />
		</FormGroup>
		<div className="mt-1">
			<input type="submit" value={t('Login.SendLink')} className="btn-ptmd" disabled={state || !email} />
		</div>
	</Form>;
}
